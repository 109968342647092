import Project, { ProjectProps } from "./Project";

let projectJson = require("../data/projectData.json");
const Projects = () => {
  return (
    <>
      <div
        className="HomePage"
        style={{
          padding: 20,
          textAlign: "center",
          margin: "auto",
        }}
      >
        <div className="BlurbContainer">
          <div>
            <text style={{ fontWeight: "bold", fontSize: "2em" }}>
              Projects.
            </text>
          </div>
        </div>
        <div
          style={{ flexDirection: "column", fontSize: "1.5em", padding: 20 }}
        >
          <div>
            <text>
              These are a few projects that I have made in my spare time. Press
              the arrows to cycle through images, and press the expand/shrink
              button to change the size.
            </text>
          </div>
          {projectJson.map((project: ProjectProps) => {
            return (
              <div
                style={{
                  marginBottom: 20,
                  padding: 20,
                  paddingBottom: 25,
                  borderLeft: "6px solid",
                  borderBottom: "6px solid",
                  borderLeftColor: "whitesmoke",
                  borderBottomColor: "rgba(75,75,75,1)",
                  backgroundColor: "rgba(240,255,255,0.25)",
                }}
                key={project.name}
              >
                <Project {...project} key={project.name}></Project>{" "}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Projects;
