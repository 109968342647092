import { NavLink } from "react-router-dom";
import "../styles/NavBar.css";

type NavBarProps = {
  routes: NavBarDestination[];
};

type NavBarDestination = {
  route: string;
  label: string;
};

const NavBar = (props: NavBarProps) => {
  return (
    <nav className="Nav">
      <div className="Name">Jonah Fritz</div>
      <div className="NavLabelContainer">
        {props.routes.map((dest) => {
          return (
            <NavLink
              end
              to={dest.route}
              className={({ isActive }) =>
                isActive ? "NavLabel ActiveNavLabel" : "NavLabel"
              }
              key={dest.route}
            >
              {dest.label}
            </NavLink>
          );
        })}
      </div>
      <div hidden={true} style={{ display: "flex", marginLeft: "auto" }} />
    </nav>
  );
};

export default NavBar;
