import Bio from "./Bio";
import "../styles/Home.css";

const Home = () => {
  return (
    <div
      className="HomePage"
      style={{
        padding: 20,
        textAlign: "center",
        margin: "auto",
      }}
    >
      <div className="BlurbContainer">
        <div>
          <text style={{ fontWeight: "bold", fontSize: "2em" }}>About me.</text>
        </div>
      </div>
      {
        // Image carousel here?
      }
      <div className="HomeImageContainer">
        <img
          src="img/selfie.jpg"
          style={{ maxHeight: 400 }}
          alt="A man with glasses stands with a lake far below."
        ></img>
      </div>
      <div style={{ display: "inline-block", maxWidth: 700 }}>
        <Bio />
      </div>
    </div>
  );
};

export default Home;
