import ImageCarousel from "./ImageCarousel";

export type ProjectProps = {
  name: string;
  link: string;
  imageUris: any[];
  tech: string[];
  description: string;
  key: string;
};
const Project = ({
  name,
  link,
  imageUris,
  tech,
  description,
}: ProjectProps) => {
  return (
    <div key={name}>
      <a style={{ fontWeight: "bold" }} href={link}>
        {name}
      </a>
      <ImageCarousel imageUrls={imageUris} />
      <div style={{ flexDirection: "row" }}>
        {tech.map((t, index) => {
          return <text key={t}>{index < tech.length - 1 ? t + ", " : t}</text>;
        })}
      </div>
      <text>{description}</text>
    </div>
  );
};

export default Project;
