import { FaMicrosoft, FaAmazon } from "react-icons/fa";

const Bio = () => {
  return (
    <div style={{ fontSize: "1.5em" }}>
      <div style={{ padding: 20 }}>
        <text>
          I am a professional software engineer currently based in Seattle. I
          have been an avid programmer since middle school, with experience
          writing production code using Java, C#, Python, Javascript, and more.
          While attaining my B.S. in Software Engineering from the Rochester
          Institute of Technology, I successfully completed internships and
          co-ops at some amazing technology companies, including{" "}
          <a
            href="https://www.marginedge.com"
            style={{ color: `rgba(200, 220, 255)` }}
          >
            MarginEdge
          </a>
          ,{" "}
          <a
            href="https://www.ibm.com"
            style={{ color: `rgba(200, 220, 255)` }}
          >
            IBM
          </a>
          ,{" "}
          <a
            href="https://www.amazon.com"
            style={{ color: `rgba(200, 220, 255)` }}
          >
            Amazon <FaAmazon />
          </a>
          , and{" "}
          <a
            href="https://www.microsoft.com"
            style={{ color: `rgba(200, 220, 255)` }}
          >
            Microsoft <FaMicrosoft />
          </a>
          (where I have worked as a software engineer since graduating in 2022).
          My{" "}
          <a
            target="_blank"
            href="./doc/Jonah Fritz Résumé.pdf"
            style={{ color: `rgba(200, 220, 255)` }}
          >
            résumé
          </a>{" "}
          shows both academic and professional success.
        </text>
      </div>
      <div style={{ padding: 20 }}>
        <text>
          I am a fast learner, and I approach problems with tenacity, leading me
          to quickly gain a working knowledge of new domains and technologies. I
          collaborate well with others, and I take particular pride in knowing
          my work is positively affecting the day-to-day experiences of others.
          I am currently in Microsoft Security working to fight spam in Outlook.
        </text>
      </div>
      <div style={{ padding: 20 }}>
        <text>
          Outside of tech, I have contributed a small body of writing to the
          Super Smash Bros. community with player blurbs that can be found
          throughout various yearly rankings and articles that can be found in
          multiple issues of the fanzine{" "}
          <a
            href="https://issuu.com/majorzine"
            style={{ color: `rgba(200,220,255)` }}
          >
            MAJOR
          </a>
          .
        </text>
      </div>
    </div>
  );
};

export default Bio;
