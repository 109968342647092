import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Contact from "./components/Contact";
import Home from "./components/Home";
import NavBar from "./components/NavBar";
import Projects from "./components/Projects";
import "./styles/App.css";
import "./styles/Global.css";

const routes = [
  {
    route: "/",
    label: "Home",
  },

  { route: "/projects", label: "Projects" },
  { route: "/contact", label: "Contact me" },
  //{ route: "/blog", label: "Blog" },
];

const App = () => {
  return (
    <div className="App">
      <Router>
        <NavBar routes={routes} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/projects" element={<Projects />} />
          {
            //<Route path="/blog" element={<Blog />} />
          }
        </Routes>
      </Router>
    </div>
  );
};

export default App;
