import { useState } from "react";
import { FaExpand, FaCompress } from "react-icons/fa";

type ImageCarouselProps = {
  imageUrls: string[];
};

const ImageCarousel = ({ imageUrls }: ImageCarouselProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const prevSlide = () => {
    const newIndex =
      currentIndex === 0 ? imageUrls.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const nextSlide = () => {
    const newIndex =
      currentIndex === imageUrls.length - 1 ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  return (
    <div
      className="carousel"
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ImageCarouselArrow
        direction="left"
        clickFunction={prevSlide}
        glyph="&#9664;"
      />
      <ImageSlide url={imageUrls[currentIndex]} />
      <ImageCarouselArrow
        direction="right"
        clickFunction={nextSlide}
        glyph="&#9654;"
      />
    </div>
  );
};

type ImageSlideProps = {
  url: string;
};
const ImageSlide = ({ url }: ImageSlideProps) => {
  const [zoomed, setZoomed] = useState(false);
  return (
    <div style={{ position: "relative" }}>
      {zoomed ? (
        <FaCompress
          style={{
            top: "5%",
            left: "85%",
            position: "absolute",
            fontSize: "3rem",
            backgroundColor: `rgba(4, 120, 87, .5)`,
            color: "white",
            opacity: "75%",
          }}
          onClick={() => setZoomed(!zoomed)}
        />
      ) : (
        <FaExpand
          style={{
            top: "5%",
            left: "85%",
            position: "absolute",
            fontSize: "3rem",
            backgroundColor: `rgba(4, 120, 87, .3)`,
            color: "white",
            opacity: "75%",
          }}
          onClick={() => setZoomed(!zoomed)}
        />
      )}

      <img
        src={url}
        style={
          zoomed
            ? { maxHeight: 1200, maxWidth: 1600 }
            : { maxHeight: 400, maxWidth: 600 }
        }
        alt=""
      />
    </div>
  );
};

type ImageCarouselArrowProps = {
  direction: "left" | "right";
  clickFunction: any;
  glyph: any;
};
const ImageCarouselArrow = ({
  direction,
  clickFunction,
  glyph,
}: ImageCarouselArrowProps) => {
  return (
    <div
      className={`image-carousel-arrow-${direction}`}
      onClick={clickFunction}
      style={{ padding: 20 }}
    >
      {glyph}
    </div>
  );
};
export default ImageCarousel;
