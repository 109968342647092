export type ContactCardProps = {
  displayText: string;
  icon: any;
  hyperlink: string;
  displayTitle: string;
};

const ContactCard = ({
  displayText,
  icon,
  hyperlink,
  displayTitle,
}: ContactCardProps) => {
  return (
    <div
      style={{
        marginBottom: 20,
        padding: 20,
        paddingBottom: 25,
        borderLeft: "6px solid",
        borderBottom: "6px solid",
        borderLeftColor: "whitesmoke",
        borderBottomColor: "rgba(75,75,75,1)",
        backgroundColor: "rgba(240,255,255,0.25)",
        width: 500,
      }}
    >
      <a href={hyperlink}>
        {" "}
        <div>{icon}</div>
        <div>
          <text>{displayTitle}</text>
        </div>
        {
          // Dividing line
        }
        <div>
          <text>{displayText}</text>
        </div>
      </a>
    </div>
  );
};

export default ContactCard;
