import ContactCard from "./ContactCard";
import { FaLinkedin, FaGithub, FaEnvelope } from "react-icons/fa";

const Contact = () => {
  return (
    <>
      <div
        className="HomePage"
        style={{
          padding: 20,
          textAlign: "center",
          margin: "auto",
        }}
      >
        <div className="BlurbContainer">
          <div>
            <text style={{ fontWeight: "bold", fontSize: "2em" }}>
              Contact me.
            </text>
          </div>
        </div>
        <div
          style={{ flexDirection: "column", fontSize: "1.5em", padding: 20 }}
        >
          <div>
            <text>
              Know of any interesting opportunities? Have any questions? If so,
              please feel free to contact me using the information below.
            </text>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <ContactCard
              displayText="jonahfritz@outlook.com"
              icon={<FaEnvelope />}
              hyperlink="mailto://jonahfritz@outlook.com"
              displayTitle="Email"
            />
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <ContactCard
              displayText="/in/jonah-fritz"
              icon={<FaLinkedin />}
              hyperlink="https://www.linkedin.com/in/jonah-fritz"
              displayTitle="LinkedIn"
            />
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <ContactCard
              displayText="fickleEfrit"
              icon={<FaGithub />}
              hyperlink="https://www.github.com/fickleEfrit"
              displayTitle="GitHub"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
